/* src/assets/fonts/fonts.css */

@font-face {
  font-family: "Satoshi";
  src: url("./Satoshi-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Satoshi";
  src: url("./Satoshi-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Satoshi";
  src: url("./Satoshi-Italic.otf") format("opentype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Satoshi";
  src: url("./Satoshi-BoldItalic.otf") format("opentype");
  font-weight: 700;
  font-style: italic;
}

@import url("./assets/fonts/fonts.css");

body,
html {
  font-family: "Satoshi", serif;
  font-optical-sizing: auto;
  scroll-behavior: smooth;
}

.navlink-button {
  &:hover {
    background-color: lightgrey;
    border-radius: 4px;
  }
}

@media screen and (max-width: 768px) {
  .home-page-title {
    font-size: 30px;
  }
}
@media screen and (min-width: 768px) {
  .home-page-title {
    font-size: 60px;
  }
}

.media-link {
  &:hover {
    color: #0e7caf !important;
  }
}

.get-involved-tab {
  font-weight: 600;
  background-color: white;
  width: 150px;

  &:hover {
    filter: brightness(98%);
  }

  &[data-active] {
    background-color: #156082;
  }
}
